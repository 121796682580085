/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ApplicationFieldInputDTO } from './ApplicationFieldInputDTO';
import type { ApplicationRejectDTO } from './ApplicationRejectDTO';
import type { JsonNode } from './JsonNode';

export type ApplicationDTO = {
    data?: JsonNode;
    dummy?: Array<ApplicationFieldInputDTO>;
    id?: number;
    kind?: ApplicationDTO.kind;
    rejects?: Array<ApplicationRejectDTO>;
    state?: ApplicationDTO.state;
};

export namespace ApplicationDTO {

    export enum kind {
        ONBOARDING_PF = 'ONBOARDING_PF',
    }

    export enum state {
        NEW = 'NEW',
        CHANGES_REQUESTED = 'CHANGES_REQUESTED',
        APPROVED = 'APPROVED',
        REVIEWING = 'REVIEWING',
        REJECTED = 'REJECTED',
    }


}
