import {Formik} from "formik";
import {Col, Popover, Row} from "antd";
import React from "react";
import {Styled as OnboardingStyled} from "../../onboardingStyles";
import {Styled as FormItemStyle} from "../../../../../components/FormItem/styles";
import {Checkbox, Input, InputNumber, Select} from "formik-antd";
import {AppInputs} from "generated/modelsWrapper/AppInputs";
import {OnboardingFormItem} from "../OnboardingFormItem";
import {InfoCircleOutlined} from "@ant-design/icons";
import {companyDescriptionSchema} from "common/onboarding/schema";
import {UNITED_KINGDOM_ID, useDictionary} from "data-layer/dictionary";
import {CompanyStepProps, StepProps} from "../../OnboardingSc";
import {usePersist} from "../../usePersist";
import {getFormikError} from "utils/getFormikError";
import {Button, FormikPatchTouched} from "components";
import {PhoneInput} from "components/PhoneInput";
import {Styled} from "./styles";
import {numberWithSpaces} from "../../../../../utils/isNumeric";

const TradingAsHint: React.FC = () => {
  return (
    <Styled.hintWrapper>
      Trading as is a term referred to as a business’s assumed, trade or
      fictitious name, indicating that the business is conducted and presented
      under a name other than the legal name of the legal person (or persons)
      who own it and are responsible for it. Not all businesses need DBA.
    </Styled.hintWrapper>
  );
};

const TotalSalesHint: React.FC = () => {
    return (
        <Styled.hintWrapper>
            Rolled up Total Card (Debit and Credit) sales of all the outlets.
            Round to nearest whole number (Annual)
        </Styled.hintWrapper>
    );
};

const ProductServiceSoldHint: React.FC = () => {
    return (
        <Styled.hintWrapper>
            Description of Goods and Services sold
        </Styled.hintWrapper>
    )
}

export const CompanyDescriptionStep: React.FC<CompanyStepProps & StepProps> = (
  props
) => {
  const { businessTypes, countries } = useDictionary();
  const { persist } = usePersist();
  const { initData, prevStep, nextStep } = props;

  return (
    <div>
      <OnboardingStyled.Title>Company description</OnboardingStyled.Title>
      <Formik
        initialValues={initData}
        validationSchema={companyDescriptionSchema}
        validateOnChange={true}
        validateOnMount={true}
        enableReinitialize
        onSubmit={(values) => {
          persist(values, nextStep);
        }}
      >
        {({
          values,
          touched,
          errors,
          handleSubmit,
          isValid,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <>
              <FormikPatchTouched />

              <OnboardingStyled.Content margin="small">
                <Row>
                  <Col span={24}>
                    <OnboardingFormItem
                      label="Company name"
                      error={getFormikError(
                        AppInputs.COMPANY_NAME,
                        errors,
                        touched
                      )}
                    >
                      <Input name={AppInputs.COMPANY_NAME} />
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem label="Company number">
                      <Input
                        name={AppInputs.COMPANY_NUMBER}
                        placeholder="AB123456"
                      />
                    </OnboardingFormItem>
                  </Col>
                    <Col span={24}>
                        <OnboardingFormItem
                            label="VAT number"
                            error={getFormikError(
                                AppInputs.VAT_NUMBER,
                                errors,
                                touched
                            )}
                        >
                            <Input
                                name={AppInputs.VAT_NUMBER}
                                placeholder="AB123456"
                            />
                        </OnboardingFormItem>
                    </Col>
                  <Col span={24}>
                    <OnboardingFormItem label="Company status">
                      <Select
                        name={AppInputs.COMPANY_STATUS}
                        placeholder="Please select..."
                      >
                        <Select.Option key="Active" value="Active">
                          Active
                        </Select.Option>
                        <Select.Option key="Dissolved" value="Dissolved">
                          Dissolved
                        </Select.Option>
                      </Select>
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label={
                        <Row gutter={9}>
                          <Col><FormItemStyle.inputSuggestion>Trading as</FormItemStyle.inputSuggestion></Col>
                          <Col>
                            <Popover
                              content={<TradingAsHint />}
                              trigger="click"
                              placement="right"
                              overlayClassName="identity-verification-popover"
                            >
                              <InfoCircleOutlined
                                style={{ fontSize: 16, color: "#8095a7" }}
                              />
                            </Popover>
                          </Col>
                        </Row>
                      }
                      error={getFormikError(AppInputs.DBA, errors, touched)}
                    >
                      <Input name={AppInputs.DBA} placeholder="" />
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label="Country"
                      error={getFormikError(AppInputs.COUNTRY, errors, touched)}
                    >
                      <Select
                        showSearch={true}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        name={AppInputs.COUNTRY}
                        placeholder="Please select..."
                      >
                        {countries?.map((item) => {
                          // if (item.id !== UNITED_KINGDOM_ID) {
                          //   return null;
                          // }

                          return (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </OnboardingFormItem>
                  </Col>
                    {values.COUNTRY === UNITED_KINGDOM_ID && (

                        <Col span={24}>
                            <OnboardingFormItem
                                label="County"
                                error={getFormikError(
                                    AppInputs.COUNTY,
                                    errors,
                                    touched
                                )}

                            >
                                <Input name={AppInputs.COUNTY} />
                            </OnboardingFormItem>
                        </Col>

                    )}

                  <Col span={24}>
                    <OnboardingFormItem
                      label="Registered company address"
                      error={getFormikError(
                        AppInputs.ADDRESS_LINE_1,
                        errors,
                        touched
                      )}
                    >
                      <Input
                        name={AppInputs.ADDRESS_LINE_1}
                        placeholder="E.g. 'CR0 3RL' or '36 Factory Lane'"
                      />
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label={null}
                      error={getFormikError(
                        AppInputs.ADDRESS_LINE_2,
                        errors,
                        touched
                      )}
                    >
                      <Input name={AppInputs.ADDRESS_LINE_2} />
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label="Town/City"
                      error={getFormikError(AppInputs.CITY, errors, touched)}
                    >
                      <Input name={AppInputs.CITY} />
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label="Postcode"
                      error={getFormikError(
                        AppInputs.POSTAL_CODE,
                        errors,
                        touched
                      )}
                    >
                      <Input name={AppInputs.POSTAL_CODE} />
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label="Phone number"
                      error={getFormikError(
                        AppInputs.PHONE_NUMBER,
                        errors,
                        touched
                      )}
                    >
                      <PhoneInput
                        country={"gb"}
                        value={values.PHONE_NUMBER}
                        onChange={(value: any) =>
                          setFieldValue(AppInputs.PHONE_NUMBER, value)
                        }
                      />
                    </OnboardingFormItem>
                  </Col>
                  <Col span={24}>
                    <OnboardingFormItem
                      label="Email address"
                      error={getFormikError(AppInputs.EMAIL, errors, touched)}
                    >
                      <Input
                        name={AppInputs.EMAIL}
                        placeholder="example@domain.com"
                        type="email"
                      />
                    </OnboardingFormItem>
                  </Col>

                  <Col span={24}>
                    <OnboardingFormItem label={null}>
                      <Checkbox
                        name="sameContactPerson"
                        onChange={() => {
                          setFieldValue(
                            AppInputs.PRIMARY_CONTACT_FIRST_NAME,
                            undefined,
                            true
                          );
                          setFieldValue(
                            AppInputs.PRIMARY_CONTACT_LAST_NAME,
                            undefined,
                            true
                          );
                          setFieldValue(
                            AppInputs.PRIMARY_CONTACT_PHONE_NUMBER,
                            undefined,
                            true
                          );
                          // sponsored by https://github.com/jaredpalmer/formik/issues/2059#issuecomment-612733378
                          setTimeout(() =>
                            setFieldTouched("sameContactPerson", true)
                          );
                        }}
                      >
                        <span>Use the same contact person</span>
                      </Checkbox>
                    </OnboardingFormItem>

                    {!values.sameContactPerson && (
                      <>
                        <Row>
                          <Col span={24}>
                            <OnboardingFormItem
                              label="Contact person First Name"
                              error={getFormikError(
                                AppInputs.PRIMARY_CONTACT_FIRST_NAME,
                                errors,
                                touched
                              )}
                            >
                              <Input
                                name={AppInputs.PRIMARY_CONTACT_FIRST_NAME}
                                type="text"
                              />
                            </OnboardingFormItem>
                          </Col>
                          <Col span={24}>
                            <OnboardingFormItem
                              label="Contact person Last Name"
                              error={getFormikError(
                                AppInputs.PRIMARY_CONTACT_LAST_NAME,
                                errors,
                                touched
                              )}
                            >
                              <Input
                                name={AppInputs.PRIMARY_CONTACT_LAST_NAME}
                                type="text"
                              />
                            </OnboardingFormItem>
                          </Col>
                          <Col span={24}>
                            <OnboardingFormItem
                              label="Contact person phone number"
                              error={getFormikError(
                                AppInputs.PRIMARY_CONTACT_PHONE_NUMBER,
                                errors,
                                touched
                              )}
                            >
                              <PhoneInput
                                country={"gb"}
                                value={values.PRIMARY_CONTACT_PHONE_NUMBER}
                                onChange={(value: any) =>
                                  setFieldValue(
                                    AppInputs.PRIMARY_CONTACT_PHONE_NUMBER,
                                    value
                                  )
                                }
                              />
                            </OnboardingFormItem>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>

                  <Col span={24}>
                    <OnboardingFormItem label={null}>
                      <Checkbox
                        name="sameAddress"
                        onChange={() => {
                          setFieldValue(
                            AppInputs.OFFICE_COUNTRY,
                            undefined,
                            true
                          );
                            setFieldValue(
                                AppInputs.OFFICE_COUNTY,
                                undefined,
                                true
                            );
                          setFieldValue(
                            AppInputs.OFFICE_ADDRESS_LINE_1,
                            undefined,
                            true
                          );
                          setFieldValue(
                            AppInputs.OFFICE_ADDRESS_LINE_2,
                            undefined,
                            true
                          );
                          setFieldValue(AppInputs.OFFICE_CITY, undefined, true);
                          setFieldValue(
                            AppInputs.OFFICE_POSTAL_CODE,
                            undefined,
                            true
                          );
                          // sponsored by https://github.com/jaredpalmer/formik/issues/2059#issuecomment-612733378
                          setTimeout(() =>
                            setFieldTouched("sameAddress", true)
                          );
                        }}
                      >
                        <span>
                          Does your trading name matches your business address?
                        </span>
                      </Checkbox>
                    </OnboardingFormItem>

                    {!values.sameAddress && (
                      <>
                        <Row>
                          <Col span={24}>
                            <OnboardingFormItem
                              label="Country"
                              error={getFormikError(
                                AppInputs.OFFICE_COUNTRY,
                                errors,
                                touched
                              )}
                            >
                              <Select
                                showSearch={true}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                name={AppInputs.OFFICE_COUNTRY}
                                placeholder="Please select..."
                              >
                                {countries?.map((item) => {
                                  if (item.id !== UNITED_KINGDOM_ID) {
                                    return null;
                                  }

                                  return (
                                    <Select.Option
                                      key={item.id}
                                      value={item.id}
                                    >
                                      {item.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </OnboardingFormItem>
                          </Col>
                            {values.COUNTRY === UNITED_KINGDOM_ID && (

                                <Col span={24}>
                                    <OnboardingFormItem
                                        label="County"
                                        hint={"Max 20 symbols"}
                                        error={getFormikError(
                                            AppInputs.OFFICE_COUNTY,
                                            errors,
                                            touched
                                        )}

                                    >
                                        <Input name={AppInputs.OFFICE_COUNTY} />
                                    </OnboardingFormItem>
                                </Col>

                            )}

                          <Col span={24}>
                            <OnboardingFormItem
                              label="Trading Address"
                              error={getFormikError(
                                AppInputs.OFFICE_ADDRESS_LINE_1,
                                errors,
                                touched
                              )}
                            >
                              <Input
                                name={AppInputs.OFFICE_ADDRESS_LINE_1}
                                placeholder="E.g. 'CR0 3RL' or '36 Factory Lane'"
                              />
                            </OnboardingFormItem>
                          </Col>
                          <Col span={24}>
                            <OnboardingFormItem label={null}>
                              <Input name={AppInputs.OFFICE_ADDRESS_LINE_2} />
                            </OnboardingFormItem>
                          </Col>
                          <Col span={24}>
                            <OnboardingFormItem
                              label="Town/City"
                              error={getFormikError(
                                AppInputs.OFFICE_CITY,
                                errors,
                                touched
                              )}
                            >
                              <Input name={AppInputs.OFFICE_CITY} />
                            </OnboardingFormItem>
                          </Col>
                          <Col span={24}>
                            <OnboardingFormItem
                              label="Postcode"
                              error={getFormikError(
                                AppInputs.OFFICE_POSTAL_CODE,
                                errors,
                                touched
                              )}
                            >
                              <Input name={AppInputs.OFFICE_POSTAL_CODE} />
                            </OnboardingFormItem>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>

                  <Col span={24}>
                    <OnboardingFormItem
                      label="What category best describes what you'll use Dapio for?"
                      error={getFormikError(
                        AppInputs.TYPE_OF_BUSINESS,
                        errors,
                        touched
                      )}
                    >
                      <Select
                        showSearch={true}
                        name={AppInputs.TYPE_OF_BUSINESS}
                        placeholder="Please select..."
                      >
                        {businessTypes?.map((item) => {
                          return (
                            <Select.Option key={item.id}>
                              {item.text}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </OnboardingFormItem>
                  </Col>
                  {(values.TYPE_OF_BUSINESS === "OTHER" || values.TYPE_OF_BUSINESS === "RETAIL") && (
                    <Col span={24}>
                      <OnboardingFormItem
                        label="Business description"
                        error={getFormikError(
                          AppInputs.BUSINESS_EXPLANATION,
                          errors,
                          touched
                        )}
                      >
                        <Input.TextArea
                          name={AppInputs.BUSINESS_EXPLANATION}
                          placeholder="Max 200 characters"
                          rows={8}
                          showCount
                          maxLength={200}
                        />
                      </OnboardingFormItem>
                    </Col>
                  )}
                    <Col span={24}>
                        <OnboardingFormItem
                            label={
                                <Row gutter={9}>
                                    <Col><FormItemStyle.inputSuggestion>Total sales</FormItemStyle.inputSuggestion></Col>
                                    <Col>
                                        <Popover
                                            content={<TotalSalesHint />}
                                            trigger="click"
                                            placement="right"
                                            overlayClassName="identity-verification-popover"
                                        >
                                            <InfoCircleOutlined
                                                style={{ fontSize: 16, color: "#8095a7" }}
                                            />
                                        </Popover>
                                    </Col>
                                </Row>
                            }
                            error={getFormikError(
                                AppInputs.TOTAL_SALES,
                                errors,
                                touched
                            )}

                        >
                            <InputNumber
                                name={AppInputs.TOTAL_SALES}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}

                                formatter={(value) =>
                                    `${numberWithSpaces(value)}`
                                }

                                parser={(value) => value!.replace(" ", "")}
                            />
                        </OnboardingFormItem>
                    </Col>
                    <Col span={24}>
                        <OnboardingFormItem
                            label={
                                <Row gutter={9}>
                                    <Col><FormItemStyle.inputSuggestion>Product service sold</FormItemStyle.inputSuggestion></Col>
                                    <Col>
                                        <Popover
                                            content={<ProductServiceSoldHint />}
                                            trigger="click"
                                            placement="right"
                                            overlayClassName="identity-verification-popover"
                                        >
                                            <InfoCircleOutlined
                                                style={{ fontSize: 16, color: "#8095a7" }}
                                            />
                                        </Popover>
                                    </Col>
                                </Row>
                            }
                            error={getFormikError(
                                AppInputs.PRODUCT_SERVICE_SOLD,
                                errors,
                                touched
                            )}

                        >
                            <Input.TextArea
                                name={AppInputs.PRODUCT_SERVICE_SOLD}
                                placeholder="Max 250 characters"
                                rows={8}
                                showCount
                                maxLength={250}/>
                        </OnboardingFormItem>
                    </Col>
                </Row>
              </OnboardingStyled.Content>

              <OnboardingStyled.Footer>
                <Row gutter={{ xs: 10, sm: 10, md: 25 }}>
                  <Col>
                    <Button
                      type="secondary-transparent"
                      onClick={() => prevStep()}
                    >
                      Back
                    </Button>
                  </Col>
                  <Col flex={1}>
                    <Button
                      type="primary-transparent"
                      onClick={() => handleSubmit()}
                      disabled={!isValid}
                      block
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </OnboardingStyled.Footer>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
